import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { ProductFeature } from 'components/QuoteSummary/ProductFeaturesTable';
import { CsSummaryOfCover } from 'components/QuoteSummary/SummaryOfCover';
import { getPetTypesBeingInsured } from 'helpers/getPetTypesBeingInsured';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { ProductFeatureSectionProps } from './ProductFeatureSection';

type ProductSectionContent = {
  csPetSummaryOfCoverChecklist: {
    product_feature: ProductFeature[];
  };
  allCsPetSummaryOfCover: {
    nodes: CsSummaryOfCover[];
  };
};
const query = graphql`
  query {
    allCsPetSummaryOfCover {
      nodes {
        summary_id
        summary_table_heading
        vet_fee_limits {
          limit_text
          limit_text_multipet
          default_fee_limit
          for_each_text
          or_text
          cover_duration_text
          treatment_cost_link
        }
        feature_list_heading
      }
    }
    csPetSummaryOfCoverChecklist {
      product_feature {
        display_on_products_selector {
          product_selector
        }
        included_with_products_selector {
          product_selector
        }
        applicable_pets
        modal {
          modal_id
        }
        feature_text
      }
    }
  }
`;
export const useProductFeatureSectionProps = (): ProductFeatureSectionProps => {
  const {
    csPetSummaryOfCoverChecklist: { product_feature },
    allCsPetSummaryOfCover,
  } = useStaticQuery<ProductSectionContent>(query);
  const quote = useCurrentQuote();
  const product = getProductFromQuoteOptions(quote.quoteOptions) || Product.Standard;
  const insuredPetTypes = getPetTypesBeingInsured(quote?.petInfos || []);
  const features = product_feature;
  const getApplicableFeatures = (): ProductFeature[] => {
    return features.filter(
      (feature) =>
        feature.display_on_products_selector.product_selector.includes(product) &&
        (feature.applicable_pets.includes('All cases') ||
          feature.applicable_pets.includes(insuredPetTypes))
    );
  };
  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote)
    : noOpPlaceholderReplacer;
  const theme = useTheme();
  const getCoverSummaryData = (currentProduct: Product): CsSummaryOfCover | undefined => {
    return allCsPetSummaryOfCover.nodes.find((n) => n.summary_id === currentProduct);
  };

  const coverSummaryData = getCoverSummaryData(product);
  const heading = coverSummaryData?.feature_list_heading || 'Product features';

  return {
    getApplicableFeatures,
    plainTextPlaceholderReplacer,
    product,
    theme,
    heading,
  };
};
